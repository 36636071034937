<template>
  <codemirror v-model="modelVal" :options="code_options"></codemirror>
</template>

<script>

import ncformCommon from '@ncform/ncform-common';

import { codemirror } from 'vue-codemirror'

// theme css
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'

// language js
import'codemirror/addon/selection/active-line.js'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/addon/display/autorefresh.js'

// autoCloseTags
import'codemirror/addon/edit/closetag.js'

export default {
  name: "Codeblock",
  mixins: [
      ncformCommon.mixins.vue.controlMixin
  ],
  components: {
    codemirror
  },
  data() {
    return {
      code_options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'text/x-sql',
        autoRefresh: true,
        lineWrapping: true,
        theme: 'monokai',
        showCursorWhenSelecting: true,
        highlightSelectionMatches: {
          showToken: /\w/,
          annotateScrollbar: true
        },
      }
    }
  },
}
</script>

<style scoped>

</style>