<template>
    <ColorPicker
        :onChange="changeColor"
        :value="modelVal"
    />
</template>

<script>

  import ncformCommon from '@ncform/ncform-common';
  import ColorPicker from "../colorPicker";

  export default {
    name: 'form-input-color-picker',
    mixins: [
      ncformCommon.mixins.vue.controlMixin
    ],

    props: [
      'value'
    ],

    components: {
      ColorPicker
    },

    methods: {
      changeColor(color) {
        this.$emit('input', color)
      },
      // you can handle the modelVal before $emit it ( before this.$emit('input') )
      _processModelVal(modelVal) {
        return modelVal;
      },

      // you can use this.$http to make some http requests ( this.$http is the same as axios )
    }
  }

</script>