import { get, post, put, del } from './apiRequest';

function confirmDelete(url, onSuccess) {
  let instance = new window.swal({
    title: 'Confirma Remover ?',
    text: 'Essa ação não poderá ser desfeita',
    showCancelButton: true,
    icon: 'warning',
  });

  instance.then((res) => {
      if (res.value) {
        del(url)
          .then((json) => {
            if (onSuccess) {
              onSuccess(json)
            }
          })
          .catch((err) => {
            new window.swal({
              title: 'Falha ao remover',
              text: err.message,
              icon: 'error',
            });
          });
      }
    });
}

function confirmUpdate(url, item, onSuccess, onError) {
  let request = put(url, item);
  request
    .then((json) => {
      alertOnSuccess(json.message)

      if (onSuccess) {
        onSuccess();
      }
    })
    .catch((err) => {
      alertOnError(err.message);

      if (onError) {
        onError();
      }
    });
}

function confirmStore(url, item, onSuccess, onError) {
  let request = post(url, item);
  request
    .then((json) => {
      alertOnSuccess(json.message)

      if (onSuccess) {
        onSuccess();
      }
    })
    .catch((err) => {
      alertOnError(err.message);

      if (onError) {
        onError();
      }
    });
}

function alertOnError (errorMessage) {
  new window.swal({
    title: 'Ops!',
    text: errorMessage,
    icon: 'error',
  });
}

function alertOnSuccess (message) {
  new window.swal({
    title: 'Sucesso!',
    text: message,
    icon: 'success',
  });
}

function alertOnResponseSuccess(response) {
  new window.swal({
    title: 'Sucesso!',
    text: response.message,
    icon: 'success',
  });
}

function alertOnResponseError(response) {
  new window.swal({
    title: 'Ops!',
    text: response.error || response.message,
    icon: 'error',
  });
}

export {
  confirmDelete,
  confirmUpdate,
  confirmStore,
  alertOnError,
  alertOnSuccess,
  alertOnResponseSuccess,
  alertOnResponseError
}