<template>
  <div>
    <div class="selected-value" @click="abrirModalSelecao">
      <div v-if="!!value">
        <div class="selected-titulo">
          {{ codigoExibicao }}
          <span v-if="codigoExibicao"> - </span>
          {{ tituloExibicao }}
        </div>
        <div class="selected-subtitulo">
          {{ subtituloExibicao }}
        </div>
      </div>
      <div v-else class="selected-titulo">
        {{ textoCliqueParaSelecionar || 'Clique para selecionar ...' }}
      </div>
      <div v-if="!!value && !!campoExtraExibicaoLabel">
        <div class="selected-titulo">
          {{ campoExtraExibicaoLabel }}
        </div>
        <div class="selected-subtitulo">
          {{ valorCampoExtra }}
        </div>
      </div>
      <fa-icon icon="chevron-down" style="width: 20px" />
    </div>

    <modal :name="`modal-${this.identificador}`" height="auto" width="900" :scrollable="true" :clickToClose="true">
      <CloseModal :close="closeModal" />
      <div class="modalbox">
        <div class="modalbox-content">
          <crud-base
            :url="url"
            :columns="getColumns"
            :enableSearch="true"
            :show-actions="true"
            :use-query-string="false"
          >
            <template v-slot:action-buttons="{model}">
              <CButton color="info" @click="selecionarModel(model)" size="sm" title="Selecionar">
                <strong>SELECIONAR</strong> <fa-icon icon="chevron-right" />
              </CButton>
            </template>
          </crud-base>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import CrudBase from "../crud/CrudBase";
import CloseModal from "../../components/CloseModal";

export default {
  name: "SelectModelPicker",
  components: {
    CrudBase,
    CloseModal,
  },
  props: {
    identificador: {
      required: true,
    },
    value: {
      required: false,
    },
    campoCodigoExibicao: {
      required: false,
    },
    campoTituloExibicao: {
      required: true,
    },
    campoSubtituloExibicao: {
      required: false,
    },
    campoExtraExibicaoLabel: {
      required: false,
    },
    campoExtraExibicaoValor: {
      required: false,
    },
    url: {
      required: true,
    },
    textoCliqueParaSelecionar: {
      type: String,
    },
    tituloMiddleware: {
      required: false
    },
    subtituloMiddleware: {
      required: false
    },
    campoExtraMiddleware: {
      required: false
    }
  },
  data() {
    return {
      selectedItem: {},
    }
  },
  computed: {
    getColumns() {
      let columns = [];

      if (!!this.campoCodigoExibicao) {
        columns.push({
          field: this.campoCodigoExibicao,
          label: this.campoCodigoExibicao.replaceAll("_", " ").toUpperCase(),
          sortable: false
        });
      }

      if (!!this.campoTituloExibicao) {
        columns.push({
          field: this.campoTituloExibicao,
          label: this.campoTituloExibicao.replaceAll("_", " ").toUpperCase(),
          sortable: false,
          representedAs: this.tituloMiddleware
        });
      }

      if (!!this.campoSubtituloExibicao) {
        columns.push({
          field: this.campoSubtituloExibicao,
          label: this.campoSubtituloExibicao.replaceAll("_", " ").toUpperCase(),
          sortable: false,
          representedAs: this.subtituloMiddleware
        });
      }

      if (!!this.campoExtraExibicaoLabel) {
        columns.push({
          field: this.campoExtraExibicaoValor,
          label: this.campoExtraExibicaoLabel,
          sortable: false,
          representedAs: this.campoExtraMiddleware
        });
      }

      return columns;
    },
    valorCampoExtra() {
      return this.campoExtraExibicaoValor && this.value[this.campoExtraExibicaoValor] !== undefined
          ? (this.campoExtraMiddleware
              ? this.campoExtraMiddleware(this.selectedItem)
              : this.selectedItem[this.campoExtraExibicaoValor])
          : null;
    },
    codigoExibicao() {
      return this.campoCodigoExibicao && this.value[this.campoCodigoExibicao] !== undefined
        ? this.selectedItem[this.campoCodigoExibicao]
        : null;
    },
    tituloExibicao() {
      return this.campoTituloExibicao && this.value[this.campoTituloExibicao] !== undefined
        ? (this.tituloMiddleware
            ? this.tituloMiddleware(this.selectedItem)
            : this.selectedItem[this.campoTituloExibicao])
        : null;
    },
    subtituloExibicao() {
      return this.campoSubtituloExibicao && this.value[this.campoSubtituloExibicao] !== undefined
        ? (this.subtituloMiddleware
            ? this.subtituloMiddleware(this.selectedItem)
            : this.selectedItem [this.campoSubtituloExibicao])
        : null;
    }
  },
  methods: {
    abrirModalSelecao() {
      this.$modal.show(`modal-${this.identificador}`);
    },
    closeModal() {
      this.$modal.hide(`modal-${this.identificador}`);
    },
    selecionarModel(model) {
      this.selectedItem = model;
      this.$emit('input', model);
      this.closeModal();
    },
  },
  beforeMount() {
    this.selectedItem = this.value;
  }
}
</script>

<style scoped>

.selected-value {
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: flex;
  height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.selected-value:hover {
  background-color: #fcfcfc;
}

.selected-titulo {
  font-weight: bold;
  line-height: 16px;
}

.selected-subtitulo {
  font-size: 10px;
  line-height: 14px;
}

</style>