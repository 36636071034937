export function boolean(value) {
  if (value) {
    return `Sim`
  }

  return `Não`
}

export function booleanHtml(value) {
  if (value) {
    return `<span class="badge badge-success">Sim</span>`;
  }

  return `<span class="badge badge-danger">Não</span>`
}
