import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login/Login.vue"),
    },
    {
      path: "/",
      component: () => import("./components/layout/TheContainer.vue"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("./views/Home/Home.vue"),
        },
        /// Cadastros
        {
          path: "/cadastros/api-gateway",
          name: "api-gateway",
          component: () => import("./views/APIGateway/APIGateway.vue"),
        },
        {
          path: "/cadastros/colaboradores",
          name: "colaboradores",
          component: () => import("./views/Colaboradores/Colaboradores.vue"),
        },
        {
          path: "/cadastros/contratos-e-licencas",
          name: "contratos-e-licencas",
          component: () =>
            import("./views/ContratosLicencas/ContratosLicencas.vue"),
        },
        {
          path: "/cadastros/clientes",
          name: "clientes",
          component: () => import("./views/Clientes/Clientes.vue"),
        },
        {
          path: "/cadastros/conta-corrente",
          name: "conta-corrente",
          component: () => import("./views/ContaCorrente/ContaCorrente.vue"),
        },
        {
          path: "/cadastros/tintometrico",
          name: "tintometrico",
          component: () => import("./views/Tintometrico/Tintometrico.vue"),
        },
        {
          path: "/expediente/expediente",
          name: "expediente",
          component: () => import("./views/Expediente/Expediente.vue"),
        },
        {
          path: "/cadastros/faq",
          name: "faq",
          component: () => import("./views/Faq/Faq.vue"),
        },
        {
          path: "/cadastros/produtos",
          name: "produtos",
          component: () => import("./views/Produtos/Produtos.vue"),
        },
        {
          path: "/cadastros/precificacao-cliente-final",
          name: "precificacao-cliente-final",
          component: () =>
            import(
              "./views/PrecificacaoClienteFinal/PrecificacaoClienteFinal.vue"
            ),
        },
        {
          path: "/cadastros/sac",
          name: "sac",
          component: () => import("./views/Sac/Sac.vue"),
        },
        {
          path: "/cadastros/setores",
          name: "setores",
          component: () => import("./views/Setores/Setores.vue"),
        },
        {
          path: "/cadastros/agendas",
          name: "agendas",
          component: () => import("./views/Agendas/Agendas.vue"),
        },
        {
          path: "/cadastros/modulos",
          name: "modulos",
          component: () => import("./views/Modulos/Modulos.vue"),
        },
        {
          path: "/cadastros/perfis",
          name: "perfis",
          component: () => import("./views/Perfis/Perfis.vue"),
        },
        // Rota para testar componentes
        {
          path: "/cadastros/tag-filtro",
          name: "tag-filtro",
          component: () => import("./views/TagFiltro/TagFiltro.vue"),
        },

        /// Campanhas
        {
          path: "/campanhas/campanhas",
          name: "campanhas",
          component: () => import("./views/Campanhas/Campanhas.vue"),
        },
        {
          path: "/campanhas/cupons",
          name: "cupons",
          component: () => import("./views/Cupons/Cupons.vue"),
        },
        {
          path: "/campanhas/descontos",
          name: "descontos",
          component: () => import("./views/Descontos/Descontos.vue"),
        },
        {
          path: "/campanhas/precos-promocionais",
          name: "promocoes",
          component: () => import("./views/PrecosPromocionais/PrecosPromocionais.vue"),
        },

        /// Marketing
        {
          path: "/marketing/banners",
          name: "banners",
          component: () => import("./views/Banners/Banners.vue"),
        },
        {
          path: "/marketing/vitrines",
          name: "vitrines",
          component: () => import("./views/Vitrines/Vitrines.vue"),
        },
        {
          path: "/marketing/links-integrados",
          name: "links-integrados",
          component: () => import("./views/LinkIntegracao/LinkIntegracao.vue"),
        },
        {
          path: "/marketing/galerias-imagens",
          name: "galerias-imagens",
          component: () => import("./views/Galeria/Galeria.vue"),
        },
        {
          path: "/marketing/catalogos",
          name: "catalogos",
          component: () => import("./views/Catalogos/Catalogos.vue"),
        },
        {
          path: "/marketing/canais-atendimento",
          name: "canais-atendimento",
          component: () =>
            import("./views/CanaisAtendimento/CanaisAtendimento.vue"),
        },
        {
          path: "/marketing/paginas",
          name: "paginas",
          component: () => import("./views/Paginas/Paginas.vue"),
        },
        {
          path: "/marketing/postagens",
          name: "postagens",
          component: () => import("./views/Postagens/Postagens.vue"),
        },
        {
          path: "/marketing/pagina-termos",
          name: "pagina-termos",
          component: () => import("./views/TermosLgpd/TermosLgpd.vue"),
        },
        {
          path: "/marketing/trabalhe-conosco",
          name: "trabalhe-conosco",
          component: () =>
            import("./views/TrabalheConosco/TrabalheConosco.vue"),
        },
        {
          path: "/marketing/links",
          name: "links",
          component: () => import("./views/Links/Links.vue"),
        },
        {
          path: "/marketing/logos",
          name: "logos",
          component: () => import("./views/Logos/Logos.vue"),
        },
        {
          path: "/marketing/secoes-rodape",
          name: "secoes-rodape",
          component: () => import("./views/SecoesRodape/SecoesRodape.vue"),
        },
        {
          path: "/marketing/departamentos",
          name: "departamentos",
          component: () => import("./views/Departamentos/Departamentos.vue"),
        },
        {
          path: "/marketing/marcas",
          name: "marcas",
          component: () => import("./views/Marcas/Marcas.vue"),
        },
        {
          path: "/cadastros/filiais",
          name: "filiais",
          component: () => import("./views/Filiais/Filiais.vue"),
        },
        {
          path: "/marketing/sociais",
          name: "sociais",
          component: () => import("./views/Sociais/Sociais.vue"),
        },
        {
          path: "/marketing/mershandising",
          name: "mershandising",
          component: () => import("./views/Mershandising/Mershandising.vue"),
        },
        {
          path: "/marketing/formularios",
          name: "formularios",
          component: () => import("./views/Formularios/Formularios.vue"),
        },

        /// Regra de negócio
        {
          path: "/regra-negocio/regra",
          name: "regras-negocio",
          component: () => import("./views/RegraNegocio/RegraNegocio"),
        },

        /// Entrega
        {
          path: "/entrega/condicao-entrega",
          name: "condicao-entrega",
          component: () => import("./views/Entrega/CondicaoEntrega"),
        },

        /// Pagamento
        {
          path: "/pagamento/pagamento",
          name: "pagamento",
          component: () => import("./views/Pagamento/Pagamento.vue"),
        },
        {
          path: "/pagamento/condicao-pagamento",
          name: "condicao-pagamento",
          component: () =>
            import("./views/CondicaoPagamento/CondicaoPagamento.vue"),
        },

        /// Notificações
        {
          path: "/notificacoes/notificacoes",
          name: "notificacoes",
          component: () => import("./views/Notificacoes/Notificacoes.vue"),
        },

        /// Pedidos
        {
          path: "/pedidos/pedidos-pendentes-integracao",
          name: "pedidos-pendentes-integracao",
          component: () =>
            import(
              "./views/PedidoPendenteIntegracao/PedidoPendenteIntegracao.vue"
            ),
        },
        {
          path: "/pedidos/pedidos-timeline",
          name: "pedidos-timeline",
          component: () => import("./views/Pedidos/Timeline/Timeline.vue"),
        },

        /// Configurações
        {
          path: "/configuracoes/configuracoes",
          name: "configuracoes",
          component: () => import("./views/Configuracoes/Configuracoes.vue"),
        },
        {
          path: "/configuracoes/dashboards",
          name: "dashboardas",
          component: () => import("./views/Dashboards/Dashboards.vue"),
        },
        {
          path: "/configuracoes/relatorios",
          name: "relatorios",
          component: () => import("./views/Relatorios/Relatorios.vue"),
        },
        {
          path: "/configuracoes/funis-acompanhamento",
          name: "funis-acompanhamento",
          component: () =>
            import("./views/FunilAcompanhamento/FunilAcompanhamento.vue"),
        },
        {
          path: "/configuracoes/views-templates",
          name: "views-templates",
          component: () => import("./views/ViewsTemplates/ViewsTemplates.vue"),
        },
        {
          path: "/configuracoes/campos-integracao",
          name: "campos-integracao",
          component: () =>
            import("./views/CamposIntegracao/CamposIntegracao.vue"),
        },
        {
          path: "/configuracoes/campos-adicionais",
          name: "campos-adicionais",
          component: () =>
            import("./views/CamposAdicionais/CamposAdicionais.vue"),
        },
        {
          path: "/configuracoes/sql-erp-integracao",
          name: "sql-erp-integracao",
          component: () =>
            import("./views/SqlErpIntegracao/SqlErpIntegracao.vue"),
        },
        {
          path: "/configuracoes/parametros",
          name: "parametros",
          component: () => import("./views/Parametros/Parametros.vue"),
        },
        {
          path: "/configuracoes/cargas",
          name: "cargas",
          component: () => import("./views/CargaDados/CargaDados.vue"),
        },
        {
          path: "/configuracoes/logs",
          name: "logs",
          component: () => import("./views/Logs/Logs.vue"),
        },

        // Rota para testar componentes
        {
          path: "/test-component",
          name: "test",
          component: () => import("./views/Test/TestComponent.vue"),
        },
      ],
    },
  ],
});
