function checkActiveWS() {
  if (typeof localStorage !== "undefined") {
    const ws_host = getStorage("WS_HOST");
    const ws_key = getStorage("WS_KEY");
    const ws_port = getStorage("WS_PORT");

    return (
      (!!ws_host && !!ws_key && !!ws_port) ||
      (!!window.env.WS_HOST && !!window.env.KEY && !!window.env.WS_PORT)
    );
  }
}

function getStorage(configName) {
  if (typeof localStorage !== "undefined") {
    return localStorage.getItem(configName);
  }
  return null;
}

function getEnvByKey(key) {
  return window.env[key] || env[key];
}

function getApis(env) {
  const apis = [];

  if (env.API_COUNT > 1) {
    for (let i = 1; i <= env.API_COUNT; i++) {
      apis.push(env[`API_${i}`]);
    }
  }

  return apis;
}

const config = {
  lang: window.env.LANG || "pt_br",
  api: getStorage("API_URL") || getEnvByKey("API_URL"),
  cdn: getStorage("CDN_URL") || getEnvByKey("CDN_URL"),
  ws_host: getStorage("WS_HOST") || getEnvByKey("WS_HOST"),
  ws_key: getStorage("WS_KEY") || getEnvByKey("WS_KEY"),
  ws_port: getStorage("WS_PORT") || getEnvByKey("WS_PORT"),
  apis: getApis(window.env),
  gtmToken:
    process.env.NODE_ENV !== "development" ? "GTM-PDT6TPR" : "GTM-5NXXVJ9",
  token: function () {
    return getStorage("token");
  },
  websocket: {
    active: checkActiveWS(),
    encrypted: false,
    api_key: getStorage("WS_KEY") || getEnvByKey("WS_KEY"),
    apis: env.APIS || [],
    options: {
      authEndpoint:
        (getStorage("API_URL") || window.env.API_URL) + "/broadcasting/auth",
      httpHost: getStorage("WS_HOST") || getEnvByKey("WS_HOST"),
      wsHost: getStorage("WS_HOST") || getEnvByKey("WS_HOST"),
      httpPort: getStorage("WS_PORT") || getEnvByKey("WS_PORT"),
      httpsPort: getStorage("WS_PORT") || getEnvByKey("WS_PORT"),
      wsPort: getStorage("WS_PORT") || getEnvByKey("WS_PORT"),
      wssPort: getStorage("WS_PORT") || getEnvByKey("WS_PORT"),
      authTransport: "ajax",
      disableStats: true,
      activityTimeout: 600000,
    },
  },
};

export default config;
