import config from './config'
import pusher from 'vue-pusher';
/**
 * Configure application with env vars
 */
export default function configureApp(Vue) {
  configureTitle();
  configureFavicon();
  configureWebsocket(Vue);
}

/**
 * Set title from env var
 */
export function configureTitle() {
  document.title = 'Painel Administrativo'
}

/**
 * Set favicon from env var
 */
export function configureFavicon() {
  const link = document.createElement('link');
  link.type = 'image/png';
  link.rel = 'shortcut icon';
  link.href = config.api + '/images/favicon/icon-72x72';
  document.getElementsByTagName('head')[0].appendChild(link);
}

/**
 * Configura o websocket
 * @param Vue
 */
export function configureWebsocket(Vue) {
  if (config.websocket?.active) {
    Vue.use(pusher, config.websocket);
  }
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}