<template>
  <div class="loading" v-bind:class="{ isActive: this.isActive, isAbsolute: this.isAbsolute }">
    <div class="load">
      <div class="animation"><div class="circle one"></div></div>
      <div class="animation"><div class="circle two"></div></div>
      <div class="animation"><div class="circle three"></div></div>
      <div class="animation"><div class="circle four"></div></div>
      <div class="animation"><div class="circle five"></div></div>
    </div>
    <div v-if="!!message" class="loading-message">
      {{ message }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isAbsolute: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
  }
}
</script>

<style scoped>
  /* loading */
  .loading,
  .local-loading {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.9);
  }
  .loading.isAbsolute {
    position: absolute;
  }
  .loading.isActive,
  .local-loading.isActive {
    display: block;
  }
  .local-loading {
    position: absolute;
  }

  /* load */
  .load {
    left: 50%;
    top: 50%;
    height: 44px;
    width: 44px;
    margin-left: -22px;
    margin-top: -22px;
    position: absolute;
  }
  .load .animation {
    animation: spin 5s linear 0s infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000;
    opacity: 0;
  }
  .load .animation:nth-child(2) {
    animation-delay: 125ms;
  }
  .load .animation:nth-child(3) {
    animation-delay: 250ms;
  }
  .load .animation:nth-child(4) {
    animation-delay: 375ms;
  }
  .load .animation:nth-child(5) {
    animation-delay: 500ms;
  }
  .load .circle {
    background: none repeat scroll 0 0 #376baf;
    border-radius: 100% 100% 100% 100%;
    height: 6px;
    left: 0;
    position: absolute;
    top: 0;
    width: 6px;
  }
  .load .circle.two {
    background: rgba(0, 146, 237, 0.8);
  }
  .load .circle.three {
    background: rgba(0, 146, 237, 0.6);
  }
  .load .circle.four {
    background: rgba(0, 146, 237, 0.4);
  }
  .load .circle.five {
    background: rgba(0, 146, 237, 0.2);
  }

  .loading-message {
    left: 50%;
    margin-left: -160px;
    top: calc(50% + 20px);
    width: 320px;
    position: absolute;
    text-align: center;
  }
</style>