<template>
  <span class="action-button">
    <CButton color="secondary" size="sm" @click="openModal()" title="Log de alterações" :disabled="!chave" :name="chave">
      <fa-icon icon="clipboard-list" />
    </CButton>

    <modal :open="true" :name="modalName" height="auto" width="1024" :lazy="true" :scrollable="true" :clickToClose="false">
      <CloseModal :close="closeModal" />
      <div class="modalbox">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Log de alterações">
            <div v-if="isFetching" class="">
              <LoadingMessage message="Carregando, aguarde" is-active align-center />
            </div>
            <div v-else-if="errorMessage" class="alert alert-danger">
              <pre>{{ errorMessage }}</pre>
            </div>
            <table-paginated
              v-else
              :itens="result.itens"
              :columns="columns"
              :paginator="result.paginator"
              :onPageChange="getLogs"
            />
          </CTab>
        </CTabs>
      </div>
    </modal>
  </span>
</template>

<script>

import CloseModal from "@/components/CloseModal";
import TablePaginated from "@/components/crud/TablePaginated";
import {get} from "@/helpers/apiRequest";
import {toDateTime} from "@/helpers/utils";

export default {
  name: "LogAlteracaoDados",
  props: [
    'tabela',
    'chave'
  ],
  components: {
    CloseModal,
    TablePaginated,
  },
  /**

   */
  data() {
    return {
      errorMessage: null,
      isFetching: false,
      result : {},
      columns: [
        {label: 'Ação', field: 'acao', sortable: false},
        {label: 'Colaborador', field: 'colaborador', representedAs: (row) => row.colaborador.nome, sortable: false },
        {label: 'Dados anteriores', field: 'dados_anteriores', isKeyValue: true, sortable: false },
        {label: 'Dados novos', field: 'dados_novos', isKeyValue: true, sortable: false },
        {label: 'Data/hora', field: 'datahora_criacao', sortable: false, representedAs: (row) => toDateTime(row.datahora_criacao) },
      ],
    }
  },
  computed: {
    modalName() {
      return `log-alteracao-dados-${this.tabela}-${this.chave}`;
    }
  },
  methods: {
    getLogs(page = 1) {
      this.isFetching = true;
      this.errorMessage = null;
      let tabela = encodeURI(this.tabela);
      let chave  = encodeURI(this.chave);

      get(`/admin/log/alteracao-dados/?tabela=${tabela}&chave=${chave}&page=${page}`)
        .then((response) => this.result = response)
        .catch((error) => this.errorMessage = error.message)
        .finally(() => {
          this.isFetching = false;
        })
    },
    openModal() {
      this.getLogs(1);
      this.$modal.show(this.modalName);
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
  .table tbody > tr > td {
    vertical-align: top;
    background-color: red
  }

  span.action-button {
    display: inline-block;
  }
</style>