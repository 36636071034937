<template>
  <datetime
    input-class="form-control"
    v-bind:value="value"
    v-on:input="handleChange"
    type="date"
    :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
    value-zone="local"
  />
</template>

<script>
  export default {
    name: 'input-datepicker',
    props: [
      'value',
    ],
    methods: {
      handleChange(event) {
        if (event instanceof Object) {
          this.$emit('input', event.target.value)
        } else {
          this.$emit('input', event)
        }
      }
    }
  }
</script>