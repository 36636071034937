<template>
  <div class="loading-inline" v-bind:class="{ isActive: this.isActive, alignCenter: this.alignCenter }">
    <div class="load">
      <div class="animation"><div class="circle one"></div></div>
      <div class="animation"><div class="circle two"></div></div>
      <div class="animation"><div class="circle three"></div></div>
      <div class="animation"><div class="circle four"></div></div>
      <div class="animation"><div class="circle five"></div></div>
    </div>
    <div v-if="!!message" class="loading-message">
      {{ message }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingMessage',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    alignCenter: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
  }
}
</script>

<style scoped>
  /* loading */
  .loading-inline {
    position: relative;
    display: none;
  }
  .loading-inline.isActive {
    display: block;
  }
  .loading-inline.alignCenter {
    text-align: center;
  }

  /* load */
  .load {
    height: 6px;
    width: 16px;
    display: inline-block;
    position: relative;
  }
  .load .animation {
    animation: spin 5s linear 0s infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000;
    opacity: 0;
  }
  .load .animation:nth-child(2) {
    animation-delay: 125ms;
  }
  .load .animation:nth-child(3) {
    animation-delay: 250ms;
  }
  .load .animation:nth-child(4) {
    animation-delay: 375ms;
  }
  .load .animation:nth-child(5) {
    animation-delay: 500ms;
  }
  .load .circle {
    background: none repeat scroll 0 0 #376baf;
    border-radius: 100% 100% 100% 100%;
    position: absolute;
    height: 3px;
    width: 3px;
    left: 0px;
    top: 0px;
  }
  .load .circle.two {
    background: rgba(0, 146, 237, 0.8);
  }
  .load .circle.three {
    background: rgba(0, 146, 237, 0.6);
  }
  .load .circle.four {
    background: rgba(0, 146, 237, 0.4);
  }
  .load .circle.five {
    background: rgba(0, 146, 237, 0.2);
  }
  .loading-message {
    display: inline-block;
    padding-left: 8px;
    font-weight: bold;
  }
</style>