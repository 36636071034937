<template>
    <div
      class="header"
      id="modalTitle"
    >
      <div class="divHeader" v-on:click="close">
        <span>
          <strong>X</strong>
        </span>
      </div>
    </div>
</template>

<script>
export default {
  name: "CloseModal",
  props: ["close"],
};
</script>

<style scoped>
.header {
  /*background-color: #003f6c;*/
  color: #fff;
  padding: 5px;
  width: 100%;
  font-size: 12px;
  height: 25px;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  float: right;
}
.divHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #fc4949;
  margin: 5px;
  margin-top: 15px;
}
.divHeader:hover {
  background-color: #f06464;
  cursor: pointer;
}
</style>
