<template>
  <money
    class="form-control"
    v-bind:value="value"
    v-on:input="handleChange"
    v-bind="monetary"
    maxlength="14"
  />
</template>

<script>
  export default {
    name: 'input-money',
    props: [
      'value',
    ],
    data() {
      return {
        monetary: {
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          masked: false,
        },
      }
    },
    methods: {
      handleChange(event) {
        if (event instanceof Object) {
          this.$emit('input', event.target.value)
        } else {
          this.$emit('input', event)
        }
      }
    }
  }
</script>