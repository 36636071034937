<template>
  <modal
    :name="modalName || 'crud-edit'"
    height="auto"
    width="80%"
    :lazy="true"
    :scrollable="true"
    :clickToClose="false"
  >
    <div v-if="this.alertMessage != null" class="alert alert-info mx-5 mt-5">
      {{ this.alertMessage }}
    </div>

    <CloseModal :close="closeModal" v-on:keyup.esc="closeModal" />
    <div class="modalbox">
      <div class="modalbox-content">
        <ncform
          :form-schema="formSchema"
          :form-name="form_id"
          v-model="formSchema.value"
          @submit="submit()"
        />
      </div>
    </div>
    <div class="modal-footer">
      <slot name="edit-buttons" :model="formSchema.value"></slot>
      <button type="button" v-on:click="submit()" class="btn btn-primary">
        Salvar Alterações
      </button>
      <button type="button" class="btn btn-secondary" v-on:click="closeModal">
        Fechar
      </button>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../components/CloseModal";
import { uuid } from "vue-uuid";

export default {
  name: "ModalEdit",
  props: ["formInputs", "editingItem", "onSubmit", "modalName", "alertMessage"],
  components: {
    CloseModal,
  },
  computed: {
    formSchema() {
      return {
        type: "object",
        properties: this.formInputs,
        value: this.editingItem || {},
      };
    }
  },
  data() {
    return {
      form_id: uuid.v4(),
    };
  },
  watch: {
    editingItem: function () {
      this.form_id = uuid.v4();
    },
  },
  mounted() {
    document.addEventListener("keyup", {
      handleEvent: (e) => {
        if (e.key === "Escape") {
          this.closeModal();
        }
      },
    });
  },
  methods: {
    submit() {
      this.$ncformValidate(this.form_id).then((data) => {
        if (data.result) {
          this.onSubmit({
            ...this.editingItem,
            ...this.formSchema.value,
          });
        }
      });
    },
    closeModal() {
      this.$modal.hide(this.modalName || "crud-edit");
    },
  },
};
</script>

<style scoped></style>
