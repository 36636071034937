<template>
  <money
    class="form-control"
    v-bind:value="value"
    v-on:input="handleChange"
    v-bind="percent"
    maxlength="10"
  />
</template>

<script>
  export default {
    name: 'input-percent',
    props: [
      'value',
    ],
    data() {
      return {
        percent: {
          decimal: ',',
          thousands: '.',
          suffix: '% ',
          masked: false
        },
      }
    },
    methods: {
      handleChange(event) {
        if (event instanceof Object) {
          this.$emit('input', event.target.value)
        } else {
          this.$emit('input', event)
        }
      }
    }
  }
</script>