<template>
    <modal name="crud-show" height="auto" width="900" :lazy="true" :scrollable="true" :clickToClose="false">
        <CloseModal :close="closeModal" />
        <div class="modalbox">
            <div class="modalbox-content">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>Chave</th>
                            <th>Valor</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(valor, chave) in editingItem">
                            <td>{{ chave }}</td>
                            <td>{{ valor }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="closeModal">
                Fechar
            </button>
        </div>
    </modal>
</template>

<script>
  import CloseModal from "../../components/CloseModal";

  export default {
    name: "EditForm",
    props: [
      'editingItem',
    ],
    components: {
      CloseModal,
    },
    methods: {
      closeModal() {
        this.$modal.hide('crud-show');
      }
    }
  }
</script>

<style scoped>

</style>