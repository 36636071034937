<template>
  <div>
    <chrome-picker :value="value" v-on:input="handleChangeColor"/>
  </div>
</template>

<script>
  import { Chrome } from 'vue-color'

  export default {
    name: 'colorPicker',
    components: {
      'chrome-picker': Chrome
    },
    props: [
      "value",
      "onChange",
    ],
    data() {
      return {
      }
    },
    methods: {
      handleChangeColor(color) {
        this.onChange(color.hex)
      },
      openPicker(){
        this.pickerOpen = true
      },
      closePicker(){
        this.pickerOpen = false
      },
      fnNull(e){
        e.preventDefault && e.preventDefault()
        return false
      }
    }
  }
</script>