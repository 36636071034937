<template>
  <table class="table-condensed table-striped table-bordered">
    <tr>
      <th style="width: 150px">Chave</th>
      <th style="width: 150px">Valor</th>
    </tr>
    <tr v-for="(value, key) in dataObject">
      <td>{{ key }}</td>
      <td>{{ value }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "KeyValueTable",
  props: [
    'dataObject'
  ]
}
</script>

<style scoped>
  table td, table th {
    padding: 2px;
    word-wrap: anywhere;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
</style>