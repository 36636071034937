import moment from 'moment'

export function dateFilter(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
}

export function dateTimeFilter(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
}